<template>
  <div>

    <b-row>
      <b-col md="5">
        <b-form-group
          :label="$t('Telefone')"
        >
          <vue-tel-input
            v-if="view==false"
            ref="sw095s04"
            v-model="form_sw095[index].sw095s04"
            class="form-control"
            inputmode="tel"
            :mode="configPhone.mode"
            :valid-characters-only="configPhone.validCharactersOnly"
            :auto-format="configPhone.autoFormat"
            :default-country="configPhone.defaultCountry"
            :auto-default-country="configPhone.autoDefaultCountry"
            :input-options="configPhone.inputOptions"
            :dropdown-options="configPhone.dropdownOptions"
            @input="checkValidateNumberPhone"
            @open="onDropdownOpenTelInput"
            @keydown.native.tab.prevent="$event.target.blur()"
            @blur.native.capture.prevent="inputValidateNumberPhone($refs.sw095s04, index)"
          >
            <template slot="arrow-icon">
              {{ '\xa0' }}
            </template>
            <template slot="icon-right">
              {{ '\xa0' }}
            </template>
          </vue-tel-input>
          <p
            v-else
            class="text-primary mb-0"
          >
            <template v-if="form_sw095[index].sw095s04">
              <b-link :href="`tel:${form_sw095[index].sw095s04}`">
                <feather-icon
                  size="16"
                  icon="PhoneIcon"
                  class="mr-1"
                />
                {{ form_sw095[index].sw095s04 }}
              </b-link>
            </template>
            <template v-else>
              -----
            </template>
          </p>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          :label="$t('Descritivo')"
        >
          <b-form-input
            v-if="view==false"
            v-model="form_sw095[index].sw095s05"
            type="text"
            autocomplete="off"
          />
          <p
            v-else
            class="text-primary mb-0"
          >
            {{ form_sw095[index].sw095s05 || '-----' }}
          </p>
        </b-form-group>
      </b-col>
      <b-col
        v-if="view===false"
        md="3"
      >
        <div
          class="d-none d-sm-block"
          style="margin-top: 1.95rem"
        />
        <b-button
          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
          variant="outline-danger"
          @click.stop.prevent="removeContactAdditional(index)"
        >
          <feather-icon
            icon="XIcon"
            class="mr-25"
          />
          <span>{{ $t('Eliminar') }}</span>
        </b-button>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BButton, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import { configPhone } from '@core-custom/utils/phone'
import
{
  isPossiblePhoneNumber,
  validatePhoneNumberLength,
  parsePhoneNumber,
} from 'libphonenumber-js/max'

const oVueTelInput = () => Promise.all([
  import(/* webpackChunkName: "chunk-vue-tel-input" */ 'vue-tel-input'),
  import(/* webpackChunkName: "chunk-vue-tel-input" */ 'vue-tel-input/dist/vue-tel-input.css'),
]).then(([{ VueTelInput }]) => VueTelInput)

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BLink,
    VueTelInput: oVueTelInput,
  },
  directives: {
    Ripple,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    view: {
      type: Boolean,
      default: () => false,
    },
    removeContactAdditional: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      configPhone,
    }
  },
  computed:
      {
        ...mapGetters('crm_leads_form', ['form_sw095']),
      },
  methods: {
    onDropdownOpenTelInput() {
      this.$nextTick(() => {
        const widthElInputTel = (this.$refs.sw095s04.$el.offsetWidth + 1)
        const el = this.$refs.sw095s04.$refs.list
        el.querySelector('.vti__search_box').placeholder = this.$t('Digite o nome')
        el.querySelector('.vti__search_box').addEventListener('keydown', evt => {
          const charCode = (evt.which) ? evt.which : window.event.keyCode

          if (charCode === 187) {
            evt.preventDefault()
          }
        })

        el.style.width = `${widthElInputTel}px`
      })
    },
    checkValidateNumberPhone($event, phoneObject) {
      this.configPhone.inputOptions.maxlength = 25
      try {
        const phoneNumber = parsePhoneNumber($event, phoneObject?.countryCode || '')
        if (phoneNumber) {
          const sIsPossiblePhoneNumber = isPossiblePhoneNumber(`${$event}0`) // Insert +1 character to simulate
          const sValidatePhoneNumberLength = validatePhoneNumberLength(`${$event}0`) // Insert +1 character to simulate

          if (sValidatePhoneNumberLength === 'TOO_LONG') {
            if (sIsPossiblePhoneNumber === false) {
              this.configPhone.inputOptions.maxlength = $event.length ?? 0
            }
          }
        }
      } catch (error) {
        //
      }
    },
    inputValidateNumberPhone(obj, index) {
      if (obj?.phoneObject?.number !== undefined && obj.phoneObject.number !== '') {
        try {
          const phoneNumber = parsePhoneNumber(obj.phoneObject.number, obj.phoneObject?.countryCode || '')
          if (phoneNumber) {
            const sIsValidPhoneNumber = phoneNumber.isValid()
            if (sIsValidPhoneNumber === false) {
              this.$store.dispatch('crm_leads_form/updateFieldSW095Value', {
                index,
                field: 'sw095s04',
                value: '',
              })
            } else {
              this.$store.dispatch('crm_leads_form/updateFieldSW095Value', {
                index,
                field: 'sw095s04',
                value: phoneNumber.formatInternational(),
              })
            }
          }
        } catch (error) {
          //
        }
      } else {
        this.$store.dispatch('crm_leads_form/updateFieldSW095Value', {
          index,
          field: 'sw095s04',
          value: '',
        })
      }
    },
  },
}
</script>

<style lang="scss">
.vti__search_box {
  width: 100% !important;
}
</style>
